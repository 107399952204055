import { AxiosResponse } from 'axios'
import axios from '../instance'
import { CODPendingParams, ReconcileOrderParams, ReconcileParams } from '../report/type'
import { ILinkFlashGeneratePayload, InsertReconcilePayload } from './type'

export const insertReconcile = (payload: InsertReconcilePayload) => {
	return axios.post('/report-channel/insert-reconcile', payload)
}

export const getReconcileMiddleAccount = async (params: ReconcileParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report-channel/reconcile', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getReconcileMiddleAccountOrder = async (params: ReconcileOrderParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report-channel/reconcile/order', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getMiddleAccountCodPending = async (params: CODPendingParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/report-channel/cod-pending', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteReconcile = async (reconcileId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.delete(`/report-channel/delete-reconcile/${reconcileId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const linkChannelCourier = async (shopId: string, courierId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(`/shop/link-channel-courier/${shopId}`, { courierId })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const generateLinkFlashAccount = async ({ shopId, redirectUrl}: ILinkFlashGeneratePayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/shop/generate-link-flash-account', { shopId, redirectUrl })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
